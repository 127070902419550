import { Container, Title } from '@mantine/core';

const GetInTouchSection = () => {
  return (
    <Container
      size="xl"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Title order={1}>Let's talk about our project.</Title>
    </Container>
  );
};

export default GetInTouchSection;
