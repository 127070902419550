import { Container, SimpleGrid, Stack, Title } from "@mantine/core";
import adrianCalmaImage from "../assets/adrian_calma.jpg";
import andreGenslerImage from "../assets/andre_gensler.jpg";
import mehmetMuejdeImage from "../assets/mehmet_muejde.jpg";
import romanHefleImage from "../assets/roman_hefle.jpg";
import ProfileCard from "../components/ProfileCard";

const ExpertProfilesSection = () => {
  return (
    <Container
      size="xl"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Stack align="center">
        <Title order={2} color="dimmed">
          Meet our Heads
        </Title>
        <Title order={1} mb={80}>
          Four experts with different backgrounds.
        </Title>
        <SimpleGrid cols={4} spacing="md">
          <ProfileCard
            fullName="Mehmet Müjde"
            title="Full Stack Developer"
            description=""
            imageSrc={mehmetMuejdeImage}
            homepageURL="https://www.mehmetmuejde.com"
            githubURL="https://github.com/mmuejde"
            xingURL="https://www.xing.com/profile/Mehmet_Muejde/cv"
            linkedInURL="https://www.linkedin.com/in/mehmetmuejde/"
            email="freelancer@mehmetmuejde.com"
            position="apart"
          />
          <ProfileCard
            fullName="Dr. Adrian Calma"
            title="Product Owner"
            description=""
            imageSrc={adrianCalmaImage}
            linkedInURL="https://www.linkedin.com/in/adrian-calma/"
            email="calma.adrian@gmail.com"
          />
          <ProfileCard
            fullName="Dr. André Gensler"
            title="Machine Learning Engineer"
            description=""
            imageSrc={andreGenslerImage}
            linkedInURL="https://www.linkedin.com/in/dr-andr%C3%A9-gensler-11973747/"
            email="andre.gensler@gmail.com"
          />
          <ProfileCard
            fullName="Roman Hefle"
            title="QM Consultant"
            description=""
            imageSrc={romanHefleImage}
            linkedInURL="https://www.linkedin.com/in/roman-hefle"
            email="rhe@your-ims.com"
          />
        </SimpleGrid>
      </Stack>
    </Container>
  );
};

export default ExpertProfilesSection;
