import { Box, Divider } from '@mantine/core';
import { useEffect } from 'react';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import AppFooter from './components/AppFooter';
import AppHeader from './components/AppHeader';
import { initGoggleAnalytics } from './goggle-analytics';
import ExpertProfilesSection from './sections/ExpertProfilesSection';
import GetInTouchSection from './sections/GetInTouchSection';
import IntroSection from './sections/IntroSection';
import OffersSection from './sections/OffersSection';

const App = () => {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGoggleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  //Remove google analytics cookies
  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <Box>
      <AppHeader />
      <IntroSection />
      <Divider />
      <ExpertProfilesSection />
      <Divider />
      <OffersSection />
      <Divider />
      <GetInTouchSection />
      <Divider />
      <AppFooter />
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Box>
  );
};

export default App;
