import { Box, Stack, Text, Title } from '@mantine/core';

const IntroSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        padding: '0 100px',
      }}
    >
      <Stack>
        <Title
          sx={{
            textAlign: 'justify',
            lineHeight: '1.4',
            fontSize: '48px',
            fontWeight: 'bold',
          }}
        >
          We provide Full Cycle Product Development 
        </Title>
        <Text
          sx={{
            fontSize: '32px',
          }}
          color="dimmed"
        >
          by creating software and ML products that add business value while meeting regulatory requirements.
        </Text>
      </Stack>
    </Box>
  );
};

export default IntroSection;
