import { Anchor, Group, Header, Title, rem } from '@mantine/core';

interface MenuItemProps {
  title: string;
}

const MenuItem = ({ title }: MenuItemProps) => {
  return (
    <Anchor
      sx={(theme) => ({
        display: 'block',
        lineHeight: 1,
        padding: `${rem(6)} ${rem(12)}`,
        textDecoration: 'none',
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.md,
        borderBottom: 'none',
        fontWeight: 500,
        '&:hover': {
          textDecoration: 'none',
          transition: 'color 0.3s, width 0.3s',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          color: theme.colors.dark,
        },
      })}
      onClick={(event) => event.preventDefault()}
    >
      {title}
    </Anchor>
  );
};

const AppHeader = () => {
  return (
    <Header height={64} sx={{ border: 'none' }}>
      <Group position="left" sx={{ height: '100%' }} pl={32} align="flex-end">
        <Title
          order={3}
          weight={900}
          sx={{ letterSpacing: '1px', marginRight: '32px' }}
        >
          softmindz.
        </Title>
        {/* // TODO-MMUEJDE: Uncomment me */}
        {/* <MenuItem title="Technologies" />
        <MenuItem title="Your experts" />
        <MenuItem title="Get in touch" /> */}
      </Group>
    </Header>
  );
};

export default AppHeader;
