import { Box, Group, Title } from '@mantine/core';
import { IconCopyright } from '@tabler/icons-react';
import AppAffix from './AppAffix';

const AppFooter = () => {
  return (
    <>
      <Box
        sx={{
          height: '4vh',
        }}
      >
        <Group position="center" sx={{ height: '100%' }}>
          <IconCopyright />
          <Title order={5}>softmindz. 2023</Title>
        </Group>
      </Box>
      <AppAffix />
    </>
  );
};

export default AppFooter;
