import { ActionIcon, Affix, Transition, rem } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { IconArrowUp } from '@tabler/icons-react';
//import { andreGenslerImage } from './assets/andre_gensler.png';

const AppAffix = () => {
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <Affix position={{ bottom: rem(55), right: rem(55) }}>
      <Transition transition="slide-up" mounted={scroll.y > 0}>
        {(transitionStyles) => (
          <ActionIcon
            sx={{ backgroundColor: '#f3f3f3' }}
            color="dark"
            size="3rem"
            style={transitionStyles}
            onClick={() => scrollTo({ y: 0 })}
          >
            <IconArrowUp size="3rem" />
          </ActionIcon>
        )}
      </Transition>
    </Affix>
  );
};

export default AppAffix;
