import {
  ActionIcon,
  Card,
  Group,
  Image,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconBrandXing,
  IconHome,
  IconMail,
} from '@tabler/icons-react';

interface ProfileCardProps {
  fullName: string;
  title: string;
  description: string;
  imageSrc: string;
  homepageURL?: string;
  linkedInURL?: string;
  xingURL?: string;
  githubURL?: string;
  email: string;
  position?: 'apart' | 'left';
  margin?: string;
}

const ProfileCard = ({
  imageSrc,
  title,
  description,
  fullName,
  linkedInURL,
  githubURL,
  homepageURL,
  xingURL,
  email,
  position = 'left',
}: ProfileCardProps) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      sx={{ width: '18vw' }}
    >
      <Card.Section>
        <Image src={imageSrc} height={350} alt={'Profile Image ' + fullName} />
      </Card.Section>
      <Stack spacing={0} p={20}>
        <Text weight={500} color="dimmed">
          {title}
        </Text>
        <Title order={2}>{fullName}</Title>
        <Space h={20} />
        <Group position={position}>
          {email && (
            <ActionIcon
              size="2rem"
              onClick={() => {
                window.open(
                  `mailto:${email}?subject=Let's talk about our project&body=Wie können wir als softmindz dich unterstützen?`
                );
              }}
            >
              <IconMail size="2rem" />
            </ActionIcon>
          )}
          {homepageURL && (
            <ActionIcon
              size="2rem"
              onClick={() => {
                window.open(homepageURL, '_blank', 'noreferrer');
              }}
            >
              <IconHome size="2rem" />
            </ActionIcon>
          )}
          {linkedInURL && (
            <ActionIcon
              size="2rem"
              onClick={() => {
                window.open(linkedInURL, '_blank', 'noreferrer');
              }}
            >
              <IconBrandLinkedin size="2rem" />
            </ActionIcon>
          )}
          {xingURL && (
            <ActionIcon
              size="2rem"
              onClick={() => {
                window.open(xingURL, '_blank', 'noreferrer');
              }}
            >
              <IconBrandXing size="2rem" />
            </ActionIcon>
          )}
          {githubURL && (
            <ActionIcon
              size="2rem"
              onClick={() => {
                window.open(githubURL, '_blank', 'noreferrer');
              }}
            >
              <IconBrandGithub size="2rem" />
            </ActionIcon>
          )}
        </Group>
      </Stack>
      {/* // TODO-MMUEJDE */}
      {/* <Text size="sm" color="dimmed">
        {description}
      </Text> */}
    </Card>
  );
};

export default ProfileCard;
