import {
  Container,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';

interface OfferSectionItemProps {
  number: string;
  title: string;
  description: string;
}

const OfferSectionItem = ({
  number,
  title,
  description,
}: OfferSectionItemProps) => {
  return (
    <Paper>
      <Title order={3} color="dimmed" pb={10}>
        {number}
      </Title>
      <Title order={2} pb={20}>
        {title}
      </Title>
      <Text color="dimmed" fz="lg" sx={{ textAlign: 'justify' }}>
        {description}
      </Text>
    </Paper>
  );
};

const OffersSection = () => {
  return (
    <Container
      size="xl"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack align="center">
        <Group position="left">
          <Title order={1} mb={80}>
            Our Services
          </Title>
        </Group>
        <SimpleGrid cols={3} spacing={60}>
          <OfferSectionItem
            number="01"
            title="UX / UI Design"
            description="By creating a design that is developed professionally, not only
              will users admire it, but it will also enhance your analytical and
              marketing performance."
          />
          <OfferSectionItem
            number="02"
            title="Web Development"
            description="No matter if you need a landing page, e-commerce platform, web app
              or mobile app, our team of experts is well-equipped to create the
              perfect solution for you."
          />
          <OfferSectionItem
            number="03"
            title="Devops and MLOps"
            description="We optimize workflows for reliable and scalable ML 
             by providing continuous integration, delivery, management, and monitoring to meet your
            objectives and regulatory requirements.
            "
          />
          <OfferSectionItem
            number="04"
            title="Machine Learning"
            description="Experience the incredible potential of machine learning with our
              team, who is dedicated to developing cutting-edge
              solutions that harness the power of this revolutionary technology."
          />
          <OfferSectionItem
            number="05"
            title="Project Management"
            description="Our product management experts ensure that your developed product
            meets customer needs,  drives business value, and make sure that the organizational
            objectives are met."
          />
          <OfferSectionItem
            number="06"
            title="Quality Management"
            description="Ensuring quality at every step. Our commitment to quality
              management guarantees the highest standards in everything we do."
          />
        </SimpleGrid>
      </Stack>
    </Container>
  );
};

export default OffersSection;
